import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import Preloader from "../src/components/Pre";
import "./App.css";
import About from "./components/About/About";
import Blogs from "./components/Blogs/Blogs";
import Contact from './components/Contact/Contact';
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar";
// import News from "./components/News/News";
import DarkMode from "./components/DarkMode/DarkMode";
import LatestNews from "./components/Latest_news/News";
import Projects from "./components/Projects/Projects";
import Resume from "./components/Resume/ResumeNew";
import Cv from "./components/CoverPage/Coverpage";
import ScrollToTop from "./components/ScrollToTop";
// import ClockWidget from './components/Clock';
import "./style.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/cover" element={<Cv />} />
          <Route path="/blogs" element={<Blogs/>} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/news" element={<News/>} />  */}
          <Route path="/latestnews" element={<LatestNews/>} />
          <Route path="/" element={<DarkMode />} />

          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        {/* <ClockWidget /> */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
